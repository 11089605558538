import { ROUTE_PATHS } from '@tapestry/shared/constants';
import { PlusIcon } from '@tapestry/shared/icons';
import { Button } from '@tapestry/weave';
import Link from 'next/link';
import { FC } from 'react';

export const ListsAppletEmptyState: FC = () => {
  return (
    <div className="text-gray-text">
      <p className="text-2xl font-bold tracking-wider">
        Looks like you haven't created any lists yet.
      </p>
      <p className="mb-8">Get started by clicking the 'Create List' button.</p>
      <Link href={ROUTE_PATHS.createNewList}>
        <Button
          status="primary"
          spacing="lg"
          iconLeft={<PlusIcon fillColor="#fff" />}
        >
          Create List
        </Button>
      </Link>
    </div>
  );
};
