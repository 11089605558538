import { LazyQueryExecFunction, QueryResult } from '@apollo/client';
import {
  IGetList,
  IGetListVariables,
  useGetList as useGetListAPI,
  useGetListLazyQuery as useGetLazyListAPI,
  ICustomHeartbeatListType,
} from '@tapestry/shared/graphql';

export const useGetList = (...args: Parameters<typeof useGetListAPI>) => {
  const useGetListsReturn = useGetListAPI(...args);

  return {
    ...useGetListsReturn,
    data: { list: useGetListsReturn?.data?.customHeartbeatList },
    isLoadingList: useGetListsReturn.loading,
  };
};

type UseGetListLazyReturn = [
  LazyQueryExecFunction<IGetList, IGetListVariables>,
  Omit<QueryResult<IGetList, IGetListVariables>, 'data'> & {
    data: { list: ICustomHeartbeatListType | undefined };
    isLoadingList: boolean;
  }
];

export const useGetListLazy = (
  ...args: Parameters<typeof useGetLazyListAPI>
): UseGetListLazyReturn => {
  const useGetListsReturn = useGetLazyListAPI(...args);
  const [func, obj] = useGetListsReturn;

  return [
    func,
    {
      ...obj,
      data: { list: obj?.data?.customHeartbeatList },
      isLoadingList: obj.loading,
    },
  ];
};
