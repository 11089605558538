import {
  AppletTemplateFrame,
  PaginationFooter,
  WidgetBar,
  WidgetBarButton,
} from '@tapestry/shared/components';
import { Stack } from '@tapestry/weave';
import { useGetLists } from '../queries/get-lists';
import { ListsList } from '../components/ListsList';
import { useRouter } from 'next/router';
import { ROUTE_PATHS } from '@tapestry/shared/constants';
import { FC } from 'react';
import { defaultGetListsVariables } from '../constants/default-get-lists-variables';
import { ListsAppletEmptyState } from '../components/ListsAppletEmptyState';
import isEmpty from 'lodash/isEmpty';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { PlusIcon } from '@tapestry/shared/icons';

export const ListsApplet: FC = () => {
  const router = useRouter();
  const [page, setPage] = useQueryParam(
    'pagination',
    withDefault(NumberParam, 1)
  );

  const {
    data: { lists, pagination },
    isLoadingLists,
    error,
    networkStatus,
    fetchMore: fetchMoreNewPaginatedThreads,
  } = useGetLists({
    variables: { ...defaultGetListsVariables, page },
  });

  const isFirstLoading = networkStatus === 1;
  const isLastPage = pagination?.currentPage === pagination?.totalPages;

  const navigateToCreateListPage = () => {
    router.push(ROUTE_PATHS.createNewList);
  };

  const handleOnPrevious = (providedOffest?: number) => {
    const previousOffset = providedOffest ? providedOffest : page - 1;

    setPage(previousOffset);
  };

  const handleOnNextFetching = (providedOffest?: number) => {
    if (!fetchMoreNewPaginatedThreads) return;

    const newOffest = providedOffest ? providedOffest : page + 1;

    fetchMoreNewPaginatedThreads({
      variables: {
        offset: newOffest,
      },
    }).then(() => setPage(newOffest));
  };

  return (
    <AppletTemplateFrame title="Lists">
      <Stack hasTopMargin>
        {/* annoucement */}

        {isEmpty(lists) && !isLoadingLists && !error ? (
          <ListsAppletEmptyState />
        ) : null}

        <ListsList listData={lists} isLoading={isLoadingLists} error={error} />

        <Stack>
          {!isEmpty(pagination) && !isEmpty(lists) && (
            <PaginationFooter
              className=""
              isLoading={isFirstLoading}
              currentPage={pagination?.currentPage}
              lastPage={pagination?.totalPages}
              isLastPage={isLastPage}
              showBoundaryPages
              boundaryPagesAmount={2}
              showFirstAndLastPage
              onPrev={handleOnPrevious}
              onNext={handleOnNextFetching}
              onPrevWithPageNumber={handleOnPrevious}
              onNextWithPageNumber={handleOnNextFetching}
            />
          )}
        </Stack>
      </Stack>

      <WidgetBar>
        <WidgetBarButton
          title="Create a new list"
          action="create-new-list"
          onPress={navigateToCreateListPage}
          variant="primary"
          icon={<PlusIcon light fillColor="currentColor" />}
        />
      </WidgetBar>
    </AppletTemplateFrame>
  );
};

export default ListsApplet;
