import { FC, useEffect } from 'react';
import { ModalActionButton } from '@tapestry/shared/components';
import { CloseIcon, ListsIcon, ProductIcon } from '@tapestry/shared/icons';
import {
  Button,
  Card,
  FormInputBase,
  FormTextInput,
  TwistListItem,
} from '@tapestry/weave';
import { useForm } from 'react-hook-form';
import { useModal } from '@tapestry/shared/hooks';
import isEmpty from 'lodash/isEmpty';
import {
  GetListsDocument,
  IThreadSimpleType,
  useGetThreadByTypeaheadSearch,
  useUpdateList,
} from '@tapestry/shared/graphql';
import { useToast } from '@tapestry/shared/client';
import { useRouter } from 'next/router';
import { ROUTE_PATHS } from '@tapestry/shared/constants';
import { loadable } from '@tapestry/shared/lazy-load';
import { useGetListLazy } from '../queries/get-list';
import { ListForm } from '../types';
import { SelectedProductListItem } from '../components/SelectedProductListItem';
import { defaultGetListsVariables } from '../constants/default-get-lists-variables';
import { defaultTypeAheadVariables } from '../constants/default-type-ahead-search-variables';
import { SelectedListListItem } from '../components/SelectedListListItem';
import { mapFormItemsToListItems } from '../utils/map-form-items-to-api-variables';
import { getListViewUrl } from '../utils/get-list-view-url';
import Head from 'next/head';
import { getCurrentAppInfo, getPageTitle } from '@tapestry/shared/utils';

const AddProductModal = loadable(
  () => import('../components/AddProductModal/AddProductModal'),
  { chunkName: 'add-product-modal', ssr: false }
);

const AddListModal = loadable(
  () => import('../components/AddListModal/AddListModal'),
  { chunkName: 'add-list-modal', ssr: false }
);

const mapThreadSimpleTypeToFormItems = (threads: IThreadSimpleType[]) => {
  const initialFormItems: {
    sublistItems: ListForm['sublistItems'];
    productItems: ListForm['productItems'];
  } = {
    sublistItems: [],
    productItems: [],
  };

  return threads.reduce(({ sublistItems, productItems }, item) => {
    if (item.threadType === '__super_list__') {
      sublistItems = [...sublistItems, item];
    } else {
      productItems = [...productItems, item];
    }

    return {
      sublistItems,
      productItems,
    };
  }, initialFormItems);
};

const { appInfo } = getCurrentAppInfo();

export const UpdateList: FC = () => {
  const router = useRouter();
  const listId = router.query.listId as string;
  const { addToast } = useToast();
  const AddProductModalState = useModal();
  const { open: handleAddProductModalOpen, isOpen: isAddProductModalOpen } =
    AddProductModalState;
  const addListModalState = useModal();
  const {
    open: handleAddListModalOpen,
    isOpen: isAddListModalOpen,
    close: handleAddListModalClose,
  } = addListModalState;

  // * Prefetch modal data
  useGetThreadByTypeaheadSearch({
    variables: defaultTypeAheadVariables,
  });

  const [getList] = useGetListLazy({
    variables: { listId },
    onError: () => {
      addToast({
        type: 'error',
        content:
          "An error has occured whilst fetchin your list, you'll now be redirected to your lists",
      });
      router.back();
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    watch,
  } = useForm<ListForm>({
    defaultValues: async () => {
      const { data } = await getList();

      const sublistItems = data?.customHeartbeatList?.listItems || [];

      return {
        name: data?.customHeartbeatList.name || '',
        ...mapThreadSimpleTypeToFormItems(sublistItems),
      };
    },
  });

  const listName = watch('name');
  const selectedProducts = watch('productItems') || [];
  const selectedSublists = watch('sublistItems') || [];

  const [updateList, { loading: isUpdatingList }] = useUpdateList({
    onCompleted: ({ updateCustomHeartbeatList: { id, name } }) => {
      const redirectUrl = getListViewUrl(id, name, ROUTE_PATHS.lists);

      addToast({
        type: 'success',
        content: 'List updated successfully.',
      });
      router.push(redirectUrl);
    },
    onError: () => {
      addToast({
        type: 'error',
        content: 'An error has occured whilst updating your list',
      });
    },
    refetchQueries: [
      {
        query: GetListsDocument,
        variables: defaultGetListsVariables,
      },
    ],
  });

  const handleRemoveProduct = (productId: string) => {
    setValue(
      'productItems',
      selectedProducts.filter((product) => product.id !== productId),
      {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      }
    );
  };

  const handleRemoveList = (listId: string) => {
    setValue(
      'sublistItems',
      selectedSublists.filter((list) => list.id !== listId),
      {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      }
    );
  };

  const onSubmit = (formData: ListForm) => {
    updateList({
      variables: {
        listId,
        name: formData.name,
        listItems: [
          ...mapFormItemsToListItems(formData.productItems),
          ...mapFormItemsToListItems(formData.sublistItems),
        ],
      },
    });
  };

  const pageTitle = getPageTitle(appInfo.title, 'Lists', 'Update', listName);

  useEffect(() => {
    register('productItems', { required: 'At least one product is required' });
    register('sublistItems');
  }, [register]);

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
      </Head>

      <section className="px-4">
        <div className="mx-auto mb-32 mt-4 w-full max-w-screen-xl rounded-lg bg-white px-4 py-4 sm:p-10">
          <div className="text-right">
            <button
              title="Return to Lists"
              aria-label="Return to Lists"
              className="hover:bg-gray-hover focus:bg-gray-hover inline-flex h-6 w-6  items-center justify-center rounded-full p-1 disabled:cursor-wait"
              onClick={router.back}
              onKeyUp={({ key }) => {
                if (key === 'Enter') {
                  router.back();
                }
              }}
            >
              <p className="sr-only">return to Lists</p>
              <CloseIcon light />
            </button>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <ul className="list-none">
              <TwistListItem
                icon={<ListsIcon fillColor="#fff" />}
                iconBackgroundColor="bg-purple"
              >
                <FormTextInput
                  {...register('name', { required: 'A list name is required' })}
                  label="List Name"
                  placeholder="e.g. Top 100"
                  aria-invalid={errors.name ? 'true' : 'false'}
                  error={errors.name?.message}
                />
              </TwistListItem>

              <TwistListItem
                icon={<ProductIcon fillColor="#fff" />}
                iconBackgroundColor="bg-purple"
              >
                <FormInputBase
                  label="Products"
                  name="items"
                  sublabel="Add, search and remove products for this list"
                  error={errors.sublistItems?.message}
                >
                  {isEmpty(selectedProducts) && (
                    <Card
                      bgColor="bg-gray-lightest"
                      as="p"
                      className="text-gray-text"
                    >
                      No products added yet. Click the button below to add
                    </Card>
                  )}

                  <ul className="space-y-2">
                    {selectedProducts?.map((product) => (
                      <li key={product.id}>
                        <SelectedProductListItem
                          product={product}
                          handleRemoveProduct={handleRemoveProduct}
                        />
                      </li>
                    ))}
                  </ul>

                  <Button
                    status="basic"
                    spacing="small"
                    className="mt-4"
                    onClick={handleAddProductModalOpen}
                    rounded="rounded-full"
                  >
                    Add products
                  </Button>
                </FormInputBase>
              </TwistListItem>
              <TwistListItem
                icon={<ListsIcon fillColor="#fff" />}
                iconBackgroundColor="bg-purple"
                showStrip={false}
              >
                <FormInputBase
                  label="List"
                  name="items"
                  sublabel="Add, search and remove exisiting list"
                  error={errors.sublistItems?.message}
                >
                  {isEmpty(selectedSublists) && (
                    <Card
                      bgColor="bg-gray-lightest"
                      as="p"
                      className="text-gray-text"
                    >
                      No list added yet. Click the button below to add
                    </Card>
                  )}

                  <ul className="space-y-2">
                    {selectedSublists.map((list) => (
                      <li key={list.id}>
                        <SelectedListListItem
                          list={list}
                          handleRemoveList={handleRemoveList}
                        />
                      </li>
                    ))}
                  </ul>

                  <Button
                    status="basic"
                    spacing="small"
                    className="mt-4"
                    onClick={handleAddListModalOpen}
                    rounded="rounded-full"
                  >
                    Add List
                  </Button>
                </FormInputBase>
              </TwistListItem>
            </ul>

            <ModalActionButton
              isLoading={isUpdatingList}
              primaryButtonText="Update List"
              disabled={{ primary: !isDirty }}
              onCancel={router.back}
            />
          </form>
        </div>

        {isAddProductModalOpen && (
          <AddProductModal
            modalState={AddProductModalState}
            selectedProducts={selectedProducts}
            setValue={setValue}
          />
        )}

        {isAddListModalOpen && (
          <AddListModal
            modalState={addListModalState}
            onClose={handleAddListModalClose}
            selectedLists={selectedSublists}
            setValue={setValue}
          />
        )}
      </section>
    </>
  );
};
