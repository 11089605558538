import {
  ICustomHeartbeatListTypeEnum,
  IGetListsVariables,
} from '@tapestry/shared/graphql';

export const defaultGetListsVariables: IGetListsVariables = {
  listType: ICustomHeartbeatListTypeEnum.Personal,
  limit: 7,
  page: 1,
};
