import {
  IGetThreadByTypeaheadSearchVariables,
  IThreadTypeEnum,
} from '@tapestry/shared/graphql';

export const defaultTypeAheadVariables: IGetThreadByTypeaheadSearchVariables = {
  threadType: IThreadTypeEnum.Product,
  filters: {},
  limit: 30,
  search: '',
  page: 1,
};
