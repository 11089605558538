import { useGetLists as useGetListsAPI } from '@tapestry/shared/graphql';

export const useGetLists = (...args: Parameters<typeof useGetListsAPI>) => {
  const useGetListsReturn = useGetListsAPI(...args);

  return {
    ...useGetListsReturn,
    data: {
      lists: useGetListsReturn?.data?.customHeartbeatLists.data || [],
      pagination: useGetListsReturn?.data?.customHeartbeatLists.pagination,
    },
    isLoadingLists: useGetListsReturn.loading,
  };
};
