import { ApolloError } from '@apollo/client';
import { ICustomHeartbeatListSimpleType } from '@tapestry/shared/graphql';
import { Stack } from '@tapestry/weave';
import { ListListItem } from './ListListItem';

interface ListsListProps {
  listData: ICustomHeartbeatListSimpleType[];
  isLoading: boolean;
  error: ApolloError | undefined;
}

export const ListsList = ({ listData, isLoading, error }: ListsListProps) => {
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error && !isLoading) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Stack spacing="xsmall" as="ul">
      {listData.map((listItem) => {
        return <ListListItem key={listItem.id} listItem={listItem} />;
      })}
    </Stack>
  );
};
