import { ICustomHeartbeatListSimpleType } from '@tapestry/shared/graphql';
import { AnalyseButton, Circle, ListItem } from '@tapestry/weave';
import Link from 'next/link';
import { ListsIcon } from '@tapestry/shared/icons';
import { ROUTE_PATHS } from '@tapestry/shared/constants';
import { useModal, useURIReturnPath } from '@tapestry/shared/hooks';
import { MoreButtonEllipsis } from '@tapestry/shared/components';
import { IDropDownActionItem } from '@tapestry/types';
import { loadable } from '@tapestry/shared/lazy-load';
import { FC } from 'react';
import { getListViewUrl } from '../utils/get-list-view-url';

const DeleteListModal = loadable(
  () => import('../components/DeleteListModal/DeleteListModal'),
  { chunkName: 'delete-list-modal', ssr: false }
);

export const ListListItem: FC<{
  listItem: ICustomHeartbeatListSimpleType;
}> = ({ listItem }) => {
  const modalState = useModal();
  const thisPageURI = useURIReturnPath();
  const analyseURL = getListViewUrl(listItem.id, listItem.name, thisPageURI);

  const ELLIPSIS_ITEMS: IDropDownActionItem[] = [
    {
      label: 'Delete',
      action() {
        modalState.open();
      },
    },
  ];

  return (
    <ListItem
      key={listItem.id}
      justify="between"
      className="focus-within:bg-gray-hover hover:bg-gray-hover transition-colors duration-150"
    >
      <Link
        href={ROUTE_PATHS.build.list.edit(listItem.id)}
        className="flex items-center w-full focus:outline-none"
      >
        <Circle className="flex-shrink-0 bg-purple text-white">
          <ListsIcon fillColor="currentColor" />
        </Circle>

        <div className="text-left pl-2 md:pl-4">
          <h2 className="text-base font-bold md:text-lg">{listItem.name}</h2>
          <p className="text-gray-text text-sm -mt-1">
            <span>
              {listItem.listItemsCount} product
              {listItem.listItemsCount > 1 ? 's' : ''}
            </span>

            {listItem.listCount > 0 ? (
              <>
                <span> - </span>
                <span>
                  {listItem.listCount} list
                  {listItem.listCount > 1 ? 's' : ''}
                </span>
              </>
            ) : null}
          </p>
        </div>
      </Link>

      <div className="flex items-center space-x-2 sm:space-x-4 pl-2 sm:pl-4">
        <Link title="View this list in Realtime" href={analyseURL}>
          <span className="sm:hidden">
            <AnalyseButton variant="icon" />
          </span>

          <span className="hidden sm:block">
            <AnalyseButton />
          </span>
        </Link>

        <MoreButtonEllipsis items={ELLIPSIS_ITEMS} ellipsisSize="small" />
      </div>

      {modalState.isOpen && (
        <DeleteListModal state={modalState} listItem={listItem} />
      )}
    </ListItem>
  );
};
