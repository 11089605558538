import { IThreadSimpleType } from '@tapestry/shared/graphql';
import { MinusIcon, ProductIconWithBubble } from '@tapestry/shared/icons';
import { FC } from 'react';

interface SelectedProductListItemProps {
  product: IThreadSimpleType;
  handleRemoveProduct: (productId: string) => void;
}

export const SelectedProductListItem: FC<SelectedProductListItemProps> = ({
  product,
  handleRemoveProduct,
}) => {
  return (
    <button
      type="button"
      onClick={() => handleRemoveProduct(product.id)}
      onKeyDown={({ key }) => {
        if (key === 'Enter') {
          handleRemoveProduct(product.id);
        }
      }}
      className="w-full text-left p-2 sm:p-5 flex items-center box-border list-none rounded-md border"
    >
      <span className="flex flex-row items-center overflow-x-hidden flex-grow">
        <span className="mr-2 sm:mr-4 h-10 w-10 sm:h-12 sm:w-12 rounded-full">
          <ProductIconWithBubble />
        </span>

        <p
          title={product?.title || ''}
          className="truncate-2-lines text-sm sm:text-base font-semibold text-black"
        >
          {product?.title || ''}
        </p>
      </span>

      <div className="border-gray-border flex-shrink-0 ml-2 sm:ml-4 inline-flex h-7 w-7 items-center justify-center rounded-full border text-blue bg-white sm:h-10 sm:w-10 sm:p-2">
        <MinusIcon light fillColor="currentColor" />
      </div>
    </button>
  );
};
