import { IThreadSimpleType } from '@tapestry/shared/graphql';

export const mapFormItemsToListItems = (formItems: IThreadSimpleType[]) => {
  if (!formItems) return [];

  return formItems.map((formItems) => ({
    id: formItems.id,
    threadType: formItems.threadType,
  }));
};
