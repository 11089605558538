import { ROUTE_PATHS } from '@tapestry/shared/constants';

const getListViewUrl = (id: string, name: string, returnTo: string) =>
  ROUTE_PATHS.build.heartbeat({
    threadType: 'list',
    qp: `listId=${encodeURIComponent(id)}&listName=${encodeURIComponent(
      name
    )}&returnTo=${returnTo}`,
  });

export { getListViewUrl };
